import Footer from "./global/Footer";

import React, {lazy, Suspense} from "react";

const SideBySideComponent = lazy(() => import("./sections/SideBySide"));
const suspenseLoader = () => <p>Loading...</p>

function Layout(props){
    return (
			<>
				{/* <Header /> */}
				<main className="align-self-center">
					<Suspense fallback={suspenseLoader()}>
						<SideBySideComponent />
					</Suspense>
				</main>
				<Footer />
			</>
		); 
}

export default Layout;