
function Footer(){
    return (
			<footer className="align-self-end">
				<div>
					<h1>LATREZ-DECLERCQ</h1>
				</div>
				<div>
					<address>
						Sint-Maartensveldstraat 17
						<br />
						8851 Ardooie - Koolskamp
						<br />
						BTW: BE 0862.724.235
						<br />
						<br />
						Tel.: <a href="tel:+32475410044">0475 41 00 44</a> (tijdens kantooruren)
						<br />
						E-mail: <a href="mailto:info@latrez.be">info@latrez.be</a>
					</address>
				</div>
			</footer>
		);    
}

export default Footer;